import File from "@/scripts/models/file.model";
import Project from "@/scripts/models/project.model";
export default class ProjectLandingPageConfig {
	constructor() {
		this.id = 0;
		this.heroAlignment = 0;
		this.heroFile = new File();
		this.heroImageOpacity = 0.7;
		this.heroOverlayOpacity = 0.3;
		this.simulationTitle = "Quer doar seu imposto de renda?";
		this.simulationParagraph = "Pela declaração completa do imposto de renda, você pode destinar até 6% do seu imposto de renda para uma doação. Faça uma simulação e descubra o seu potencial de doação.";
		this.project = new Project();
		this.beneficiariesDistributionMale = 0;
		this.beneficiariesDistributionFemale = 0;
		this.beneficiariesDistributionByAgeClassification = [];
	}
}